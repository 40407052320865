<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";
import useResponsive from "@/utils/responsive-util"
import supportingInfoContainer from '@/components/supportingInfoContainer.vue';
import { Section } from "@/store/section.interface";
import { QExpansionItem } from 'quasar';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import { useCommunityStore } from "@/store/communityStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useUserStore } from "@/store/userStore";
import { useI18n } from "vue-i18n";
import { Context } from "@/store/context.interface";


// STORE
const { isMobile } = useResponsive()
const communityStore = useCommunityStore();
const { sectionLoaded } = storeToRefs(communityStore);
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { t } = useI18n();

// PROPS
const props = defineProps<{
    context?: string;
    contextAttachment: Array<Context>;
    sections?: Array<Section>;
    isSection?: boolean;
    activeSection?: Section;
    video?: string,
    mailingList?: boolean,
    attachmentsOpened: boolean;
}>();


// REFS
const subscriptionEmail = ref<string>(user?.value.email);

// EMITS
const emit = defineEmits<{
    (e: "changeSection", newValue: number): void;
    (e: "subscription", email: string): void;
}>();

//FUNCTIONS
function changeSection(id: number) {
    emit("changeSection", id)
    setTimeout(() => {
        const element = document.getElementById("sectionContainer")
        element?.scrollIntoView({ behavior: "smooth", block: "end" })
    }, 200);
}

function subscriptionAction(){
  if(subscriptionEmail.value){
    emit("subscription", subscriptionEmail.value)
  }
}
</script>

<template>
    <div class="context-menu">
        <q-list bordered>
            <q-expansion-item v-bind:default-opened="!isSection && !isMobile">
                <template v-slot:header>
                    <q-item-section avatar>
                        <q-icon name="o_description"></q-icon>
                    </q-item-section>

                    <q-item-section v-if="!isSection">
                        {{ t('welcome') }}
                    </q-item-section>
                    <q-item-section v-else>
                        {{ t("consultation.context.title") }}
                    </q-item-section>
                </template>
                <template v-slot>
                    <q-card>
                        <q-card-section>
                            <div class="context-container__description" v-html="props.context"></div>
                            <div v-if="props.video" class="context-container__description">
                                <LiteYouTubeEmbed
                                      :id="props.video"
                                      :title="props.context"
                                />
                            </div>
                        </q-card-section>
                    </q-card>
                </template>
            </q-expansion-item>
            <q-expansion-item v-if="props.mailingList">
                <template v-slot:header>
                    <q-item-section avatar>
                        <q-icon name="mail"></q-icon>
                    </q-item-section>
                    <q-item-section>
                        {{ t("consultation.mailingList.title") }}
                    </q-item-section>
                </template>
                <template v-slot>
                    <q-card>
                        <q-card-section>
                          <div class="context-container__mailingList" >
                            {{ t("consultation.mailingList.content") }}
                            <q-input type="email" :label='t("consultation.mailingList.inputBox")' v-model="subscriptionEmail"/>
                            <q-btn @click="subscriptionAction">{{t("consultation.mailingList.button")}}</q-btn>
                          </div>
                        </q-card-section>
                    </q-card>
                </template>
            </q-expansion-item>
            <q-expansion-item  v-bind:default-opened="props.attachmentsOpened" v-if="props.contextAttachment.length >= 1">
                <template v-slot:header>
                    <q-item-section avatar>
                        <q-icon name="link"></q-icon>
                    </q-item-section>

                    <q-item-section>
                        <div class="attachments">
                            <div>
                                {{ t("consultation.context.attachment", { count: props.contextAttachment.length }) }}
                            </div>
                            <div class="attachments_count">
                                [ {{ props.contextAttachment.length }} ]
                            </div>
                        </div>
                    </q-item-section>
                </template>
                <template v-slot>
                    <q-card>
                        <q-card-section>
                            <supporting-info-container :supportingInfos="props.contextAttachment"/>
                        </q-card-section>
                    </q-card>
                </template>
            </q-expansion-item>

            <q-expansion-item
                v-if="!isSection && !isMobile && props.sections.length >= 1 && props.activeSection && sectionLoaded">
                <template v-slot:header>
                    <q-item-section avatar>
                        <q-icon name="o_view_agenda"></q-icon>
                    </q-item-section>

                    <q-item-section>
                        Sections
                    </q-item-section>
                </template>
                <template v-slot>
                    <q-card>
                        <q-card-section>
                            <div
                                class="menu-subsection"
                                v-for="section in sections"
                                @click="changeSection(section.id)"
                                :class="{'active': section.id === props.activeSection.id}">
                                <span class="menu-subsection__title">{{ getTranslation(section, 'title') }}</span>
                            </div>
                        </q-card-section>
                    </q-card>
                </template>
            </q-expansion-item>
        </q-list>
    </div>
</template>

<style lang="scss">
.context-menu {
    font-size: $body-text;
    cursor: pointer;

    .q-item.q-item-type {
        padding: $space-sm $space-md;
        height: auto;
        display: flex;
        align-content: center;
        flex-wrap: wrap;

        .q-icon {
            font-size: $small-title;
            padding: 0 $space-sm;
        }
    }

    .q-expansion-item:hover {
        background-color: $color-neutral-grey-100;
        font-weight: bold;
        border-radius: 8px
    }

    .q-expansion-item--expanded .q-item {
        color: $color-primary;

        &.q-item.q-item-type .q-icon {
            color: $color-primary;
        }

    }

    .attachments {
        display: flex;
        flex-direction: row;
        gap: $space-sm;

        &_count {
            font-weight: bold;
        }
    }

    .q-expansion-item__content .q-card {
        border-radius: 0px 0px 8px 8px;
    }

}

//scrollbar styles
::-webkit-scrollbar {
    border-radius: 5px;
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: $color-neutral-grey-400-50;
    border-radius: 10px;
}

.context-container__description {
    padding: $space-sm;
    font-weight: 400;
    max-height: 45vh;
    overflow-y: auto;

    a {
      font-size: 16px;
      color: #007BFF;                   /* Primary link color */
      text-decoration: none;             /* Removes underline by default */
      border-bottom: 2px solid transparent; /* Subtle bottom border */
      transition: color 0.3s, border-color 0.3s;  /* Smooth transition for color and border */

    }

    a:hover {
       color: #0056b3;                    /* Darkens on hover */
      border-color: #0056b3;
    }

    a:active {
     outline: none;
      border-color: #004080;             /* More prominent color when focused *//* Slight press effect */
    }
}

.context-container__mailingList {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}


.menu-subsection {
    padding: $space-sm;
    padding-left: $space-md;
    font-weight: 400;
    font-size: $body-text;

    &__title {
        &:hover {
            color: $color-neutral-grey-400-50;
        }
    }

    &.active {
        color: $color-primary !important;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .context-container__description {
        max-height: 35vh;
    }

}
</style>