import axios, { CancelTokenSource } from 'axios';

import { Config } from '@/config';
import apiService from "@/services/api.service";
import { DTOHomepageItem } from '@/store/homepageItem.interface';
import { useHomepageItemStore } from '@/store/homepageItemStore';

const CancelToken = axios.CancelToken;
let cancelTokenConsultations: CancelTokenSource | null = null;

class HomepageItemsService {
  async search(page: number, q: string | null = null): Promise<DTOHomepageItem[]> {
    if (cancelTokenConsultations) cancelTokenConsultations.cancel('Multiple requests.');
    cancelTokenConsultations = CancelToken.source();
    const body = { page, pagesize: 10, q };
    const response = await apiService.post(`${Config.api.backend.urlV2}/homepage-items/:search`, body);
    const data = response.data as DTOHomepageItem[];
    if (data) useHomepageItemStore().setHomepageItems(data);
    return data;
  }
}

export const homepageItemsService = new HomepageItemsService();
