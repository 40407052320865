type Paths = {
    [key: string]: string;
};

export const paths: Paths = {
    root: "/",
    consultations_list: "/consultations",
    about: "/about-us",
    profile: "/profile",
    consultations: "/consultations/:slug",
    propositions: "/propositions/:id",
    consultation: "/consultation/:id",
    joinUs: "/join-us",
    notFound: "/not-found",
    login: "/login",
    logout: "/logout",
    project: "/projects/:slug",
    projectTemplate: "/project-template/:slug"
};
