<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { rules } from "@/utils/validation-rules-util"

// PROPS
const props = withDefaults(defineProps<{
    model: string | number
    placeholder?: string
    disabled?: boolean
    type?: "text" | "number" | "email"
    readonly?: boolean
    label?: string
    rules?: string
    lazyRules?: boolean
    mask?: string
    hide?: boolean
    searchable?: boolean
    clearable?: boolean

}>(), {
    type: "text"
});

// EMITS
const emit = defineEmits<{
    (e: "update", newValue: string | number): void;
    (e: "toggleEye", hide: boolean): void;
    (e: "search", localModelValue: string | number): void;
    (e: "clear"): void;
}>();


// COMPUTED
const localModelValue = computed({
    get() {
        return modelValue.value;
    },
    set(newValue) {
        modelValue.value = newValue;
        emit("update", newValue);
    }
});

const getMask = computed(() => {
    return props.mask
})

const getRules = computed(() => {
    return rules[props.rules as keyof typeof rules]
})

//FUNCTIONS
function clearValue() {
    modelValue.value = null;
}

function toggleEye() {
    isHidden.value = !isHidden.value
    emit("toggleEye", isHidden.value)
}

function handleEnter() {
    if (props.searchable) {
        search();
    }
}

function handleUpdate(value: any) {
    if (props.searchable && !value) {
        clear();
    }
};

function clear() {
    emit("clear");
}

function search() {
    if (hasSearchMinLength()) {
        emit("search", localModelValue.value);
    }
}

function hasSearchMinLength() {
    return (localModelValue.value as string ?? '')?.length >= 3;
}

// REFS
const modelValue = ref<string | number>(props.model);
const isHidden = ref<boolean>(true)


// WATCH
watch(props, () => {
    if (props.hide) {
        modelValue.value = props.model
    }
});

defineExpose({ clearValue });

</script>

<template>
    <q-input
        class="base-inputs"
        outlined
        v-model="localModelValue"
        :rules="getRules"
        :placeholder="props.placeholder"
        :disable="props.disabled"
        :readonly="props.readonly"
        :mask="getMask"
        :lazy-rules="props.lazyRules"
        :type="props.type"
        :clearable="props.clearable"
        @keyup.enter="handleEnter()"
        @clear="clear()"
        @update:model-value="handleUpdate"
    >
        <template v-slot:append v-if="props.hide">
            <q-icon
                :name="isHidden ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="toggleEye()"
            />
        </template>
        <template v-slot:append v-if="props.searchable">
            <q-icon
                name="search"
                :class="{'cursor-pointer': true, 'disabled': !hasSearchMinLength()}"
                :disable="!hasSearchMinLength()"
                @click="search()"
            />
        </template>
    </q-input>
</template>

<style lang="scss">
.base-inputs {
    width: 100%;

    &.q-field--readonly {
        .q-field__native {
            cursor: not-allowed !important;
        }

        .q-field__control {
            background-color: $color-neutral-grey-300 !important;
        }
    }

    .q-field {

        &__inner {
            background-color: $color-neutral-white;
            border-radius: 4px;
        }

        &__append {
            height: 44px;
        }

        &__bottom {
            padding: 4px 12px;
        }

        &__control {
            &--readonly {
                background-color: red !important;
            }

            height: 44px !important;
        }

        &__control:before {
            border: none !important;
        }
    }
}

input {
    border: 0 !important;

    &:focus {
        border: 0 !important;
    }
}
</style>