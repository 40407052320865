<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { getTranslation } from "@/utils/data-display-utils";

import ProjectDescription from '@/components/project/ProjectDescription.vue';
import ModuleImageText from '@/components/project/ModuleTextImage.vue';
import ModuleVideo from "@/components/project/ModuleVideo.vue";
import ModuleConsultation from "@/components/project/ModuleConsultation.vue";
import ModuleAttachments from "@/components/project/ModuleAttachments.vue";
import ModuleFrequentlyAskedQuestions from "@/components/project/ModuleFrequentlyAskedQuestions.vue";
import HomepageItemPrivateNoAccessDialog from "@/components/dialogs/HomepageItemPrivateNoAccessDialog.vue";
import ModuleSubscription from "@/components/project/ModuleSubscription.vue";
import ModuleTimeline from "@/components/project/ModuleTimeline.vue";

import { Project } from "@/store/project.interface";
import { Module } from "@/store/module.interface";
import { useCommunityStore } from "@/store/communityStore";
import { useUserStore } from "@/store/userStore";
import { modulesService } from "@/services/modules.service";
import { projectsService } from "@/services/projects.service";
import { constant } from "@/constant";
import Banner from "@/components/layout/Banner.vue";

const route = useRoute();

// STORE
const communityStore = useCommunityStore();
const userStore = useUserStore();

// CONST
const userIsAllowed = ref({ allowed: false });
const homepageItemPrivateNoAccessDialogRef = ref(null);
const slug = route.params.slug as string;
const { t } = useI18n();

// PROPS
const props = defineProps<{ isTemplate: boolean }>();


// LIFECYCLE
onMounted(async () => {
    if (props.isTemplate) {
        await projectsService.getProjectTemplateBySlug(slug);
    } else {
        await projectsService.getProjectBySlug(slug);
    }
    userIsAllowed.value = communityStore.isUserAllowedAccessProject(project.value, userStore.user)
    if (userIsAllowed.value.allowed || props.isTemplate) {
        await modulesService.getProjectModules(project.value.id);
    } else {
        openHomepageItemPrivateNoAccessPopup()
    }
})


// COMPUTED
const project = computed<Project | null>(() => {
    if (!communityStore.community.projects) {
        return null;
    }
    return communityStore.community.projects.find(project => project.slug == slug);
})

const modules = computed<Module[]>(() => {
    return project.value.modules;
})


// FUNCTIONS
function openHomepageItemPrivateNoAccessPopup() {
    homepageItemPrivateNoAccessDialogRef.value.openDialog()
}
</script>

<template>
    <div class="project-container">
        <div class="project" v-if="userIsAllowed.allowed">
            <banner :message="t('project.banner')" v-if="props.isTemplate" />
            <div class="project__title">
                <h1>{{ getTranslation(project, 'title') }}</h1>
            </div>
            <div class="project__description">
                <ProjectDescription :text="getTranslation(project, 'description')" :banner="project.bannerFile" />
            </div>
            <div v-for="module in modules" :key="module.id" class="project__module-text">
                <ModuleImageText v-if="module.module_type === constant.ProjectModule.Type.Text" :module="module"/>
                <ModuleVideo v-if="module.module_type === constant.ProjectModule.Type.Video" :module="module"/>
                <ModuleConsultation v-if="module.module_type === constant.ProjectModule.Type.Consultation" :module="module"/>
                <ModuleAttachments v-if="module.module_type === constant.ProjectModule.Type.Attachments" :module="module"/>
                <ModuleFrequentlyAskedQuestions v-if="module.module_type === constant.ProjectModule.Type.FrequentlyAskedQuestions" :module="module"/>
                <ModuleSubscription v-if="module.module_type === constant.ProjectModule.Type.Subscription" :module="module" />
                <ModuleTimeline v-if="module.module_type === constant.ProjectModule.Type.Timeline" :module="module"/>
            </div>
        </div>
        <HomepageItemPrivateNoAccessDialog ref="homepageItemPrivateNoAccessDialogRef"
                                           :home-link="userStore.isUserLogged"
                                           :need-login="!userStore.isUserLogged"
                                           :item="project"/>
    </div>
</template>

<style lang="scss">
.project-container {
    display: flex;
    justify-content: center;
    padding: $space-md 0;

    .project {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: $space-lg;

        &__title {
            margin: $space-md 0 0 0;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .project-container {
        padding: $space-sm 0;

        .project {
            width: 90%;
        }
    }
}
</style>