<script setup lang="ts">
import { computed, ref, onMounted, inject, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { setLocale } from "@/plugins/i18n";
import { storeToRefs } from "pinia";
import { VueCookies } from "vue-cookies";

import BaseButtonToggle from "@/components/base/BaseButtonToggle.vue";
import HomepageItemCard from "@/components/HomepageItemCard.vue";
import HomepageItemPrivateNoAccessDialog from "@/components/dialogs/HomepageItemPrivateNoAccessDialog.vue";
import BaseInput from "@/components/base/BaseInput.vue";

import { Consultation } from "@/store/consultation.interface";
import { useCommunityStore } from '@/store/communityStore';
import { User, useUserStore } from "@/store/userStore";
import { getTranslation, removeTimestamp } from "@/utils/data-display-utils";
import { constant } from "@/constant"
import { HomepageItem } from "@/store/homepageItem.interface";
import { Project } from "@/store/project.interface";
import { homepageItemsService } from "@/services/homepageItems.service";
import { useHomepageItemStore } from "@/store/homepageItemStore";



// STORE
const communityStore = useCommunityStore();
const homepageItemStore = useHomepageItemStore();
const userStore = useUserStore();
const { community } = storeToRefs(communityStore)

// CONST
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const cookies = inject<VueCookies>('$cookies')

// REFS
const toggleStateView = ref('ongoingValue');
const homepageItemPrivateNoAccessDialogRef = ref(null);
const needLogin = ref(false);
const selectedItemNeedAuth = ref();
const searchInput = ref();

const language = route.params.lang as string;
if (language && community.value.languageEnabled.includes(language)) {
    cookies.set('locale', language)
    setLocale(language);
}

// Pagination
const currentPage = ref(1);
const chunks = computed(() => {
  const c = [];
  for (let i = 0; i < filteredHomepageItems.value.length; i += constant.Pagination.DefaultRowsPerPage) {
    c.push(filteredHomepageItems.value.slice(i, i + constant.Pagination.DefaultRowsPerPage));
  }
  return c;
});

// COMPUTED
const preLaunchState = computed(() => {
    return {
        name: "consultation.status.pre-launch",
        icon: "pending",
        quantity: homepageItemStore.preLaunchHomepageItems.length
    }
});

const ongoingState = computed(() => {
    return {
        name: "consultation.status.ongoing",
        icon: "lock_open",
        quantity: homepageItemStore.ongoingHomepageItems.length
    }
});

const completedState = computed(() => {
    return {
        name: "consultation.status.completed",
        icon: "lock_clock",
        quantity: homepageItemStore.completedHomepageItems.length
    }
});

const archivedState = computed(() => {
    return {
        name: "consultation.status.archived",
        icon: "archive",
        quantity: homepageItemStore.archivedHomepageItems.length
    }
});

const getHomepageItemState = computed(() => {
    let stateMessage = '';
    switch (toggleStateView.value) {
        case 'ongoingValue':
            stateMessage = t("consultation.no-ongoing");
            break;
        case 'completedValue':
            stateMessage = t("consultation.no-completed");
            break;
        case 'archivedValue':
            stateMessage = t("consultation.no-archived");
            break;
        case 'preLaunchValue':
            stateMessage = t("consultation.no-pre-launch");
            break;
        default:
            stateMessage = ''; // Handle default case
    }
    return stateMessage;
});

const filteredHomepageItems = computed<HomepageItem[]>(() => {
    if (!(userStore.isAdmin() || userStore.isEditor())) {
        switch (toggleStateView.value) {
            case 'completedValue':
                return homepageItemStore.completedHomepageItems;
            default:
                return homepageItemStore.ongoingHomepageItems;
        }
    } else {
        switch (toggleStateView.value) {
            case 'completedValue':
                return homepageItemStore.completedHomepageItems;
            case 'archivedValue':
                return homepageItemStore.archivedHomepageItems;
            case 'preLaunchValue':
                return homepageItemStore.preLaunchHomepageItems;
            default:
                return homepageItemStore.ongoingHomepageItems;
        }
    }
});

// LIFECYCLE
onMounted(async () => {
    await homepageItemsService.search(1);
    document.title = getTranslation(community.value, 'title');
    if (route.query?.projectId) {
        const projectId = parseInt(route.query.projectId as string);
        const project = communityStore.getProject(projectId);
        const result = communityStore.isUserAllowedAccessProject(project, userStore.user);
        if (!result.allowed) {
            selectedItemNeedAuth.value = project;
            openHomepageItemPrivateNoAccessPopup();
        }
    }
})

// FUNCTIONS
function enterToCard(item: HomepageItem) {
    if (item.itemType === constant.HomePageItem.Type.Consultation) {
        const userIsAllowed = communityStore.isUserAllowedAccessConsultation(item as Consultation, userStore.user as User)
        if (userIsAllowed.allowed) {
            router.push({
                name: "consultations",
                params: { slug: item.slug }
            });
        } else if (userIsAllowed.reason === 'NotAllowedByAdmin') {
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup();
        } else if (userIsAllowed.reason === 'login') {
            needLogin.value = true;
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup()
        }
    } else if (item.itemType === constant.HomePageItem.Type.Project) {
        const userIsAllowed = communityStore.isUserAllowedAccessProject(item as Project, userStore.user as User)
        if (userIsAllowed.allowed) {
            router.push({
                name: "project",
                params: { slug: item.slug }
            });
        } else if (userIsAllowed.reason === 'NotAllowedByAdmin') {
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup();
        } else if (userIsAllowed.reason === 'login') {
            needLogin.value = true;
            selectedItemNeedAuth.value = item;
            openHomepageItemPrivateNoAccessPopup()
        }
    }
}

function openHomepageItemPrivateNoAccessPopup() {
    homepageItemPrivateNoAccessDialogRef.value.openDialog();
}

async function searchHomepageItems(q: string) {
    await homepageItemsService.search(1, q);
    currentPage.value = 1;
}

async function clearHomepageItems() {
    await homepageItemsService.search(1);
    currentPage.value = 1;
}

// WATCH
watch(toggleStateView, () => { currentPage.value = 1; });

</script>
<template>
    <div class="community">
        <div class="community__header">
            <img
                :src="removeTimestamp(communityStore.community.bannerFile.url)"
                :alt="t('alt-text.community.banner')"
            />
        </div>
        <div class="community__identification">
            <div class="community__identification-title">
                <h1>{{ getTranslation(communityStore.community, 'title') }}</h1>
            </div>
            <div class="community__identification-description">
                {{ getTranslation(communityStore.community, 'description') }}
            </div>
        </div>
        <div class="community__content">
            <div class="community__content-wrapper">
                <div class="community__homepageitems">
                    <div class="community__homepageitems-featured"
                         v-if="communityStore.community.featuredProject"
                         @click="enterToCard(communityStore.community.featuredProject)"
                    >
                        <HomepageItemCard
                            :startDate="communityStore.community.featuredProject.beginDate"
                            :endDate="communityStore.community.featuredProject.endDate"
                            :title="getTranslation(communityStore.community.featuredProject, 'title')"
                            :image="communityStore.community.featuredProject.bannerFile ? communityStore.community.featuredProject.bannerFile.url : removeTimestamp(communityStore.community.bannerFile.url)"
                            :is-private="communityStore.community.featuredProject.visibility === constant.Project.Type.Private"
                            :item-type="communityStore.community.featuredProject.itemType"
                            :slug="communityStore.community.featuredProject.slug"
                            is-featured
                        />
                    </div>
                    <div class="community__homepageitems-actions">
                        <BaseButtonToggle
                            v-model="toggleStateView"
                            :preLaunchState="preLaunchState"
                            :ongoingState="ongoingState"
                            :completedState="completedState"
                            :archivedState="archivedState"
                            :isAdmin="userStore.isAdmin() || userStore.isEditor()"
                        />
                        <BaseInput :model="searchInput"
                                   :placeholder="t('search')"
                                   searchable
                                   clearable
                                   @search="searchHomepageItems"
                                   @clear="clearHomepageItems"/>
                    </div>
                    <div class="community__homepageitems-grid">
                        <div
                            v-if="filteredHomepageItems.length"
                            v-for="item in chunks[currentPage - 1]"
                            class="community__homepageitems-card"
                            @click="enterToCard(item)"
                        >
                            <HomepageItemCard
                                :startDate="item.beginDate"
                                :endDate="item.endDate"
                                :title="getTranslation(item, 'title')"
                                :image="item.bannerFile ? item.bannerFile.url : removeTimestamp(communityStore.community.bannerFile.url)"
                                :key-information="item.summary"
                                :is-private="item.type === constant.Consultation.Type.Private || item.visibility === constant.Project.Type.Private"
                                :item-type="item.itemType"
                                :slug="item.slug"
                            />
                        </div>
                    </div>
                    <q-pagination
                        v-if="filteredHomepageItems.length"
                        v-model="currentPage"
                        direction-links
                        :max="chunks.length"
                        round
                        :max-pages="5"
                        :ellipses="true"
                        :boundary-numbers="true"
                        color="grey"
                        active-color="secondary"
                        class="community__pagination"
                        gutter="sm"
                    ></q-pagination>
                    <div class="community__homepageitems-card--empty" v-else>
                        {{ getHomepageItemState }}
                    </div>
                </div>
            </div>
        </div>
        <HomepageItemPrivateNoAccessDialog ref="homepageItemPrivateNoAccessDialogRef" :home-link="false"
                                           :item="selectedItemNeedAuth"
                                           :need-login="needLogin"/>
    </div>
</template>

<style lang="scss">
.community {
    &__header {
        max-height: 360px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            max-height: 360px;
        }
    }

    &__identification {
        background-color: $color-neutral-white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-md;
        padding: $space-xl 0;
        margin-bottom: $space-xl;

        &-title {
            text-align: center;
            display: flex;
            justify-self: center;
            max-width: $content-width;
        }

        &-description {
            font-size: $body-text;
            max-width: $content-width;
            padding: 0 $space-lg;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        margin: 0 $space-3xl;
        
        &-wrapper {
            width: $content-width;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: $space-md;
            margin-bottom: $space-xl;
        }
        
    }

    &__logo {
        width: $space-2xl;
        height: $space-2xl;
        border-radius: 50%;
        border: 1px solid $color-primary-12;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-neutral-white;
        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__homepageitems {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-featured {
            margin: $space-xl 0;
        }

        &-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: $space-xl;
            margin: $space-sm 0;

            .q-btn-group {
                height: inherit;
                min-height: 44px;
            }

            .q-field {
                max-width: 40%;
            }
        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(calc(50% - 32px), 1fr));
            gap: 32px;
            margin: $space-lg 0;
        }

        &-card {
            flex: 1 1 calc(50% - 8px);
            box-sizing: border-box;

            &--empty {
                text-align: center;
                background-color: $color-neutral-white;
                border-radius: 8px;
                padding: 32px;
                color: $color-neutral-grey-400;
            }
        }
    }

    &__pagination {
        justify-content: flex-end;
        margin:0 $space-md;
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .community {
        &__homepageitems {
            &-actions {
                display: flex;
                flex-direction: column;

                .q-field {
                    max-width: 100%;
                }
            }

            &-grid {
                display: flex;
                flex-direction: column;
                gap: 32px;   
                width: 100%;     
            }

            &-card {
                flex: none;
                width: inherit;

                .homepage-card {
                    height: 100%;
                }
            }

            &-featured {
                margin: $space-lg 0;
            }
        }

        &__identification { 
            &-description {
                padding: 0 $space-3xl;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .community {
        &__homepageitems {
            gap: 0;

            &-actions {
                display: flex;
                flex-direction: column;
                gap: $space-md;

                .q-field {
                    max-width: 100%;
                }
            }

            &-grid {
                display: flex;
                flex-direction: column;
                gap: 32px;   
                width: 100%;     
            }

            &-card {
                flex: none;
                width: inherit;

                .homepage-card {
                    height: 100%;
                }
            }

            &-featured {
                margin: 0 0 $space-sm 0;
            }
        }
        &__identification { 
            justify-content: center;
            padding: $space-lg $space-md;
            margin-bottom: $space-lg;

            &-description {
                padding: 0 $space-3xl;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .community {

        .key-infos {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &__info.is-title {
                width: 35%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: inherit;
            margin: 0 $space-md;

            &-wrapper {
                width: inherit;
            }
        }

        &__identification {
            justify-content: center;
            padding: $space-lg $space-md;
            margin-bottom: $space-lg;

            &-description {
                padding: 0;
            }
        }

        &__context {
            width: 100%;
        }

        &__homepageitems {
            gap: 0;

            &-actions {
                display: flex;
                flex-direction: column;
                gap: $space-md;
                margin: $space-lg 0;

                .q-field {
                    max-width: 100%;
                }
            }

            &-grid {
                display: flex;
                flex-direction: column;
                gap: 32px;   
                width: 100%;   
                margin: 0 0 $space-lg 0;  
            }

            &-card {
                flex: none;
                width: inherit;

                .homepage-card {
                    height: 100%;
                }
            }

            &-featured {
                margin: 0 0 $space-sm 0;
            }
        }

        &__header {
            height: 100px;
        }
    }
}
</style>