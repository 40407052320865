<script setup lang="ts">

// PROPS
const props = defineProps<{
    source: string,
    color: string,
    altText: string
}>();

</script>
<template>
    <div class="logo">
        <img :src="props.source" :alt="props.altText" />
    </div>
</template>
<style lang="scss">
.logo {
    background-color: v-bind(color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: inherit; //to be set by parent
    width: inherit; //to be set by parent

    img {
        width: 42px;
        height: 42px;
    }
}
@media screen and (max-width: $breakpoint-sm) {
    .logo {
        img {
            width: 28px;
            height: 28px;
        }
    }
}
</style>