<script setup lang="ts">

import { storeToRefs } from "pinia";
import { computed, inject, onMounted, provide, ref, watch } from "vue";
import { VueCookies } from "vue-cookies";
import { RouterView } from "vue-router";

import GTMScript from "@/components/GTMScript.vue";
import QuebecLaw96 from "@/components/QuebecLaw96.vue";
import ConsentEmailDialog from "@/components/dialogs/ConsentEmailDialog.vue";
import CookieConsent from "@/components/dialogs/CookieConsent.vue";
import Footer from "@/components/layout/Footer.vue";
import Header from "@/components/layout/Header.vue";
import { setLocale } from "@/plugins/i18n";
import { useCommunityStore } from "@/store/communityStore";
import { useUserStore } from "@/store/userStore";
import { getTranslation } from "@/utils/data-display-utils";
import useResponsive from "@/utils/responsive-util";
import { setPrimaryColors } from "@/utils/style";
import PageLoading from "@/views/PageLoading.vue";


// STORE
const communityStore = useCommunityStore();
const userStore = useUserStore();

// CONST
const cookies = inject<VueCookies>("$cookies");
const { isUserLogged } = storeToRefs(userStore);
const { community, isLoaded } = storeToRefs(communityStore);
const { isMobile } = useResponsive();
const cookieConsentDialogRef = ref(null);
const consentDialogRef = ref(null);
const results = ref(false);

// LIFECYCLE
provide("results", results);

onMounted(async () => {
    setLocale(cookies.get("locale") ?? "fr");
    setPrimaryColors();
});

// COMPUTED
const consentCookieValid = computed(() => {
    const consentCookie = cookies.get("consent");
    return (consentCookie !== undefined && consentCookie !== null)
});
const consentCookieEnabled = computed(() => {
    const consentCookie = cookies.get("consent");
    return (consentCookie !== undefined && consentCookie !== null && consentCookie !== 0);
});
const shouldShowTermsDialog = computed(() => userStore.isTermsApprovalRequired);

// FUNCTIONS
function updateResults(value: any) {
    results.value = value;
}

// WATCH
watch(isLoaded, () => {
    if (communityStore.community.faviconFile) {
        const favicon = document.getElementById("favicon")! as HTMLLinkElement;
        favicon.href = communityStore.community.faviconFile.url;
    }
    if (communityStore.community.languageEnabled.length === 1) {
        setLocale(communityStore.community.languageEnabled[0]);
    }
    if (!consentCookieValid.value) {
        cookieConsentDialogRef.value.openDialog();
    }
});
watch(isUserLogged, () => {
    if (shouldShowTermsDialog.value) {
        consentDialogRef.value.openDialog();
    }
});
</script>

<template>
    <div class="base-layout">
        <div class="base-layout__container" v-if="isLoaded">
            <Header @results="updateResults($event)"/>
            <quebec-law96 :community="community" />
            <div class="base-layout__page-content">
                <RouterView/>
            </div>
            <Footer v-if="!isMobile"/>
        </div>
        <div class="base-layout__container" v-else>
            <page-loading/>
        </div>
    </div>
    <consent-email-dialog
        ref="consentDialogRef"
        :community-title="isLoaded && getTranslation(community, 'title')"
        :organization-name="isLoaded && getTranslation(community, 'organizationName')"
    />
    <cookie-consent ref="cookieConsentDialogRef"/>
    <GTMScript :gtm-id="communityStore.getCommunityGtmId" 
               :gtm-enabled="communityStore.isCommunityGtmEnabled" 
               :cookies-enabled="consentCookieEnabled"/>
</template>
<style lang="scss">
.base-layout {
    display: flex;
    min-height: 100vh;
    width: 100%;
    position: relative;
    &__container {
        display: flex;
        flex: auto;
        flex-direction: column;
        width: 100%;
    }
    &__page-content {
        height: 100%;
    }
}
.q-notification__actions i {
    color: #fff;
}
</style>
