import { defineStore } from 'pinia';

import { constant } from '@/constant';
import { DTOHomepageItem, HomepageItem } from '@/store/homepageItem.interface';

export const useHomepageItemStore = defineStore('homepageItemStore', {
    state: () => ({
        homepageItems: [] as HomepageItem[],
        isLoaded: false,
    }),
    actions: {
        setHomepageItems(homepageItems: DTOHomepageItem[]) {
            this.homepageItems = homepageItems.map(parseHomepageItem);
            this.isLoaded = true;
        },
    },
    getters: {
        archivedHomepageItems(state): HomepageItem[] {
            return state.homepageItems.filter((item) => item.status === constant.HomePageItem.Status.Archived);
        },
        completedHomepageItems(state): HomepageItem[] {
            return state.homepageItems.filter((item) => item.status === constant.HomePageItem.Status.Completed);
        },
        ongoingHomepageItems(state): HomepageItem[] {
            return state.homepageItems.filter((item) => item.status === constant.HomePageItem.Status.Ongoing);
        },
        preLaunchHomepageItems(state): HomepageItem[] {
            return state.homepageItems.filter((item) => item.status === constant.HomePageItem.Status.PreLaunch);
        },
    }
});

export function parseHomepageItem(homepageItemDto: DTOHomepageItem): HomepageItem {
    return {
        id: homepageItemDto.id,
        itemType: homepageItemDto.item_type,
        title: homepageItemDto.title,
        status: homepageItemDto.status,
        beginDate: homepageItemDto.begin_date,
        endDate: homepageItemDto.end_date,
        bannerFile: homepageItemDto.banner_file,
        summary: homepageItemDto.summary,
        type: homepageItemDto.type,
        slug: homepageItemDto.slug,
        visibility: homepageItemDto.visibility,
        privateEmailAllowed: homepageItemDto.private_email_allowed,
    };
}