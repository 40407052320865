<script setup lang="ts">

// PROPS
const props = defineProps<{
	isAdmin?: boolean,
	preLaunchState?: {
		name: string;
		icon: string;
		quantity: number;
	};
	ongoingState: {
		name: string;
		icon: string;
		quantity: number;
	};
	completedState?: {
		name: string;
		icon: string;
		quantity: number;
	};
	archivedState?: {
		name: string;
		icon: string;
		quantity: number;
	};
}>();

// MODEL
const model = defineModel();
</script>

<template>
	<div class="base-button-toggle">
		<q-btn-toggle v-model="model"
					  no-caps
					  unelevated
					  toggle-text-color="selected"
					  class="full-width-toggle"
					  :options="[
						...(props.isAdmin
							? [{ value: 'preLaunchValue', slot: 'preLaunch' }]
							: []),
						{ value: 'ongoingValue', slot: 'ongoing' },
						{ value: 'completedValue', slot: 'completed' },
						...(props.isAdmin
							? [{ value: 'archivedValue', slot: 'archived' }]
							: []),
					  ]
">
			<template v-slot:preLaunch>
				<q-icon :name="preLaunchState.icon" />
				{{ $t(preLaunchState.name) }} [{{ preLaunchState.quantity }}]
			</template>
				
			<template v-slot:ongoing>
				<q-icon :name="ongoingState.icon" />
				{{ $t(ongoingState.name) }} [{{ ongoingState.quantity }}]
			</template>

			<template v-slot:completed>
				<q-icon :name="completedState.icon" />
				{{ $t(completedState.name) }} [{{ completedState.quantity }}]
			</template>

			<template v-slot:archived>
				<q-icon :name="archivedState.icon" />
				{{ $t(archivedState.name) }} [{{ archivedState.quantity }}]
			</template>
		</q-btn-toggle>
	</div>
</template>

<style lang="scss">
.base-button-toggle {

	.q-icon {
		margin-right: 8px;
	}

	@media screen and (max-width: $breakpoint-md) {
		.q-btn {
			width: 100%;
		}

		.full-width-toggle {
			width: 100%;
		}
	}

	.q-btn-group {
		border-radius: 6px;
		background-color: $color-neutral-white;

		.text-selected {
			font-weight: bold;
		}
	}

	.bg-primary {
		background-color: $color-primary-12 !important;
		color: $color-primary;
		border: solid 1px $color-primary;
	}
	@media screen and (max-width: $breakpoint-sm) {
		.q-btn {
			padding: $space-sm;
		}

		.full-width-toggle {
			width: 100%;
		}
	}
}
</style>
