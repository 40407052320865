<script setup lang="ts">

import { computed } from "vue";
import Facebook from "@/assets/images/facebook.svg";
import Instagram from "@/assets/images/instagram.svg";
import LinkedIn from "@/assets/images/linkedin.svg";
import X from "@/assets/images/x-twitter.svg";


// PROPS
const props = defineProps<{
    community: any,
}>();

// COMPUTED
const hasSocialNetworks = computed(() => {
    return (props.community.options.social_networks?.facebook?.enabled ||
        props.community.options.social_networks?.instagram?.enabled ||
        props.community.options.social_networks?.linkedin?.enabled ||
        props.community.options.social_networks?.x?.enabled
    )
})

</script>
<template>
    <div v-if="hasSocialNetworks" class="social-networks">
        <div v-if="props.community.options.social_networks.facebook.enabled">
            <a target="_blank" :href="props.community.options.social_networks.facebook.url">
                <img :src="Facebook">
            </a>
        </div>
        <div v-if="props.community.options.social_networks.instagram.enabled">
            <a target="_blank" :href="props.community.options.social_networks.instagram.url">
                <img :src="Instagram">
            </a>
        </div>
        <div v-if="props.community.options.social_networks.linkedin.enabled">
            <a target="_blank" :href="props.community.options.social_networks.linkedin.url">
                <img :src="LinkedIn" >
            </a>
        </div>
        <div v-if="props.community.options.social_networks.x.enabled">
            <a target="_blank" :href="props.community.options.social_networks.x.url">
                <img :src="X">
            </a>
        </div>
    </div>
</template>
<style lang="scss">
.social-networks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space-md;
    margin: $space-xs 0;

    a {
        display: flex;
        justify-content: end;
    }

    img {
        height: 13px;
    }
}
</style>