import { defineStore } from 'pinia';
import { Consultation, DTOConsultation, parseConsultation } from '@/store/consultation.interface';
import { DTOSection, parseSection, Section } from "@/store/section.interface";
import { DTOContext, parseContext } from "@/store/context.interface";
import { DTOModule, parseModule } from './module.interface';
import { CustomPage, DTOCustomPage, parseCustomPage } from "@/store/customPage.interface";
import {
    DTOForm,
    parseForm,
    PostFormCommentActionResponse,
    PostFormCommentResponse,
    PostFormVoteResponse
} from '@/store/form.Interface';
import { diffInDays, todayInTimestamp } from "@/utils/date-util";
import { constant } from "@/constant";
import { LanguageTranslation } from "@/store/language.interface";
import { User, useUserStore } from "@/store/userStore";
import { DTOProject, parseProject, Project } from './project.interface';
import { StoredFile } from './common.interface';
import { HomepageItem } from './homepageItem.interface';
import { DTOFrequentlyAskedQuestion, parseFrequentlyAskedQuestion } from './faq.interface';
import { DTOTimelineStep, parseTimelineStep } from './timelineStep.interface';

export interface DTOCommunity {
    id: number,
    slug: string,
    created_at: string,
    status: number,
    contact_email: string | null,
    website: string | null,
    phone: string | null,
    title: LanguageTranslation,
    description: LanguageTranslation,
    features: CommunityFeatures,
    banner_file: StoredFile,
    logo_file: StoredFile,
    favicon_file: StoredFile,
    language_enabled: string[],
    contact_info: ContactInfo,
    options: CommunityOptions,
    organization_name: string | null,
    projects: Project[],
    featured_project?: DTOFeaturedProject | null
}

export interface DTOFeaturedProject {
    banner_file: StoredFile;
    begin_date: Date | null,
    end_date: Date | null,
    id: number;
    slug: string;
    title: LanguageTranslation;
    visibility: number;
    private_email_allowed: PrivateAllowed | null;
}

export interface Community {
    id: number,
    slug: string,
    createdAt: string,
    status: number,
    contactEmail: string | null,
    website: string | null,
    phone: string | null
    title: LanguageTranslation,
    description: LanguageTranslation,
    features: CommunityFeatures,
    bannerFile: StoredFile,
    logoFile: StoredFile,
    faviconFile: StoredFile,
    consultations: Consultation[],
    customPages: CustomPage[],
    projects: Project[],
    languageEnabled: string[],
    contactInfo: ContactInfo,
    options: CommunityOptions,
    organizationName: string,
    featuredProject: FeaturedProject | null
}

export interface CommunityOptions {
    gender: boolean;
    export: {
        excel: boolean;
        pdf: boolean;
    },
    consultation: {
        citizen_proposal: boolean;
        i_believe_i_care: boolean;
        map: boolean;
        moderation_comment: boolean;
    }
    loi96: boolean,
    social_networks: {
        facebook: {
            enabled: boolean,
            url: string
        }
        instagram: {
            enabled: boolean,
            url: string
        }
        linkedin: {
            enabled: boolean,
            url: string
        }
        x: {
            enabled: boolean,
            url: string
        }
    },
    google_tag_manager: {
        enabled: boolean;
        container_id: string | null;
    },
    featured_project_id: number | null
}

interface ContactInfo {
    email: string,
    phone: string
}

interface CommunityFeatures {
    sociodemographicAllowed: number;
    mapsAllowed: number;
    exportAllowed: number;
    privateThemeAllowed: number;
    publicProposalAllowed: number;
    sociodemographic_white_background: number;
    moderateEmail: number;
    voila_open_id: number;
    use_gender: number;
    anon_votes: number;
    languages: string;
    notifSuperAdmin: number | null;
    IFrameEnabled: number;
    portal: number | null;
    portal_login: number;
    portal_community_url: string | null;
    portal_community_name: string | null;
    moderateEmailAdmin: number;
}

export interface FeaturedProject extends HomepageItem {}

interface PrivateAllowed {
    email: string[];
    domain: string[];
}

export const useCommunityStore = defineStore('communityStore', {
    state: () => ({
        community: {} as Community,
        isLoaded: false,
        sectionLoaded: false,
        formsLoaded: false,
        customPageLoaded: false
    }),
    actions: {
        setCommunity(dtoCommunity: DTOCommunity) {
            this.community = parseCommunity(dtoCommunity);
            this.isLoaded = true;
        },
        setConsultation(consultationDTO: DTOConsultation) {
            if (!this.community.consultations) {
                this.community.consultations = [];
            }
            this.community.consultations.push(parseConsultation(consultationDTO));
        },
        setConsultations(consultationsDTO: DTOConsultation[]) {
            this.community.consultations = consultationsDTO.map(parseConsultation);
        },
        setSections(sectionDTO: DTOSection[], consultationId: number) {
            const consultation = this.community.consultations.find(consultation => consultation.id === consultationId)!
            consultation.section = sectionDTO.map(parseSection)
            this.sectionLoaded = true;
        },
        setConsultationContext(contextDTO: DTOContext[], consultationId: number) {
            const consultation = this.community.consultations.find(consultation => consultation.id === consultationId)!
            consultation.context = contextDTO.map(parseContext)
        },
        setCustomPage(customPageDTO: DTOCustomPage[]) {
            this.community.customPages = customPageDTO.map(parseCustomPage);
            // this.community.customPages = parseCustomPage(customPageDTO)
            this.customPageLoaded = true
        },
        setProject(projectDTO: DTOProject) {
            if (!this.community.projects) {
                this.community.projects = [];
            }
            this.community.projects.push(parseProject(projectDTO));
        },
        setProjects(projectsDTO: DTOProject[]) {
            this.community.projects = projectsDTO.map(parseProject);
        },
        setProjectModules(modulesDTO: DTOModule[], projectId: number) {
            const project = this.community.projects.find(project => project.id === projectId)
            project.modules = modulesDTO.map(parseModule);
        },
        setProjectModuleFaqs(questionsDTO: DTOFrequentlyAskedQuestion[], moduleId: number, projectId: number) {
            const project = this.community.projects.find(project => project.id === projectId)
            const module = project.modules.find(s => s.id === moduleId)
            module.questions = questionsDTO.map(parseFrequentlyAskedQuestion);
        },
        setProjectModuleTimelineSteps(timelineStepsDTO: DTOTimelineStep[], moduleId: number, projectId: number) {
            const project = this.community.projects.find(project => project.id === projectId)
            const module = project.modules.find(s => s.id === moduleId)
            module.timelineSteps = timelineStepsDTO.map(parseTimelineStep);
        },
        setSectionContext(contextDTO: DTOContext[], consultationId: number, sectionId: number) {
            const consultation = this.community.consultations.find(consultation => consultation.id === consultationId)!
            const section = consultation.section.find(s => s.id === sectionId)!
            section.context = contextDTO.map(parseContext)
        },
        setModuleContext(contextDTO: DTOContext[], moduleId: number, projectId: number) {
            const project = this.community.projects.find(project => project.id === projectId)
            const module = project.modules.find(s => s.id === moduleId)
            module.context = contextDTO.map(parseContext)
        },
        setSectionForms(formDTO: DTOForm[], section: Section) {
            section.form = formDTO.map(parseForm);
        },
        getConsultationTimeLeft(consultation: Consultation): number {
            const startDate = consultation.beginDate ? consultation.beginDate : todayInTimestamp();
            const endDate = consultation.endDate;
            let diffDays: number;
            diffDays = diffInDays(startDate, endDate);
            if (diffDays <= 0) {
                return 0;
            }
            return diffDays;
        },
        addVoteToFormById(response: PostFormVoteResponse) {
            const consultation = this.community.consultations.find(c => c.id === response.consultationId);
            const section = consultation.section.find(s => s.id === response.sectionId);
            let form = section.form.find(f => f.id === response.formId);
            form.response[0] = response.vote;
            form.statistiques = response.statistiques
        },
        addCommentToFormById(response: PostFormCommentResponse) {
            const consultation = this.community.consultations.find(c => c.id === response.consultationId);
            const section = consultation.section.find(s => s.id === response.sectionId);
            let form = section.form.find(f => f.id === response.formId);
            form.comments.push(response.comment)
        },
        updateCommentAction(response: PostFormCommentActionResponse) {
            const consultation = this.community.consultations.find(c => c.id === response.consultationId);
            const section = consultation.section.find(s => s.id === response.sectionId);
            let form = section.form.find(f => f.id === response.formId);
            let comment = form.comments.find(c => c.id === response.commentId);
            switch (response.action) {
                case 'like':
                    comment.likes.push({ action: 1, userId: response.userId })
                    if(form.type === 5){
                        const this_marker = form.mapMarkers.find(marker => marker.comment_id === response.commentId);
                        this_marker.likes.push({ action: 1, userId: response.userId })
                    }
                    break;
                case 'flag':
                    comment.flags.push({ action: 2, userId: response.userId })
                    break;
                case 'unlike':
                    const likeActionIndex = comment.likes.findIndex(a => a.userId === response.userId);
                    comment.likes.splice(likeActionIndex, 1)
                    break;

                case 'unflag':
                    const flagActionIndex = comment.flags.findIndex(a => a.userId === response.userId);
                    comment.flags.splice(flagActionIndex, 1);
                    break;
            }

        },
        async getCustomPageBySlug(slug: string): Promise<CustomPage> {
            return this.community.customPages.find((customPage: any) => customPage.slug === slug);
        },
        isUserAllowedAccessConsultation(consultation: Consultation, user: User) {
            const userStore = useUserStore()
            if (consultation.type === constant.Consultation.Type.Public) {
                return { allowed: true };
            }
            if (!user.email) {
                return { allowed: false, reason: 'login' };
            }
            if (userStore.isAdmin() || userStore.isEditor(constant.HomePageItem.Type.Consultation, consultation.id)) {
                return { allowed: true };
            }
            const emailDomain = user.email.split('@');
            const private_allowed = (
              consultation.privateEmailAllowed.email.some((e: string) => e?.toLowerCase() === user.email.toLowerCase()) ||
              consultation.privateEmailAllowed.domain.some((d: string) => d?.toLowerCase() === emailDomain[1].toLowerCase())
            )
            if (private_allowed) {
                return { allowed: true }
            } else {
                return { allowed: false, reason: 'NotAllowedByAdmin' }
            }
        },
        isUserAllowedAccessProject(project: Project, user: User) {
            const userStore = useUserStore();
            if (project.visibility === constant.Project.Type.Public) {
                return { allowed: true };
            } else if (!user.email) {
                return { allowed: false, reason: 'login' };
            } else if (userStore.isAdmin() || userStore.isEditor(constant.HomePageItem.Type.Project, project.id)) {
                return { allowed: true };
            }
            const emailDomain = user.email.split('@');
            const private_allowed = (
              project.privateEmailAllowed.email.some((e: string) => e?.toLowerCase() === user.email.toLowerCase()) ||
              project.privateEmailAllowed.domain.some((d: string) => d?.toLowerCase() === emailDomain[1].toLowerCase())
            )
            if (private_allowed) {
                return { allowed: true };
            }
            return { allowed: false, reason: 'NotAllowedByAdmin' };
        },
        isConsultationCompleted(consultation: Consultation) {
            return consultation.status === constant.Consultation.Status.Completed;
        },
        isUserAllowedIfPrelaunchConsultation(consultation: Consultation) {
            const userStore = useUserStore();
            const isPrelaunch = consultation.status === constant.Consultation.Status.PreLaunch;
            return !isPrelaunch || userStore.isAdmin() || userStore.isEditor(constant.HomePageItem.Type.Consultation, consultation.id);
        },
        addProjectTemplate(projectTemplate: DTOProject){
            if (!this.community.projects) {
                this.community.projects = [];
            }
            this.community.projects.push(parseProject(projectTemplate));
        }
    },
    getters: {
        getSections(state) {
            return (consultationId: number) => state.community.consultations.find((consultation) => consultation.id === consultationId)?.section
        },
        getProject(state) {
            return (projectId: number) => state.community.projects.find((project) => project.id === projectId);
        },
        isCommunityGtmEnabled: (state) => {
            return state.community?.options?.google_tag_manager?.enabled ?? false;
        },
        getCommunityGtmId: (state) => {
            return state.community?.options?.google_tag_manager?.container_id ?? null;
        }
    }
});

function parseCommunity(dtoCommunity: DTOCommunity): Community {
    return {
        id: dtoCommunity.id,
        slug: dtoCommunity.slug,
        createdAt: dtoCommunity.created_at,
        status: dtoCommunity.status,
        contactEmail: dtoCommunity.contact_email,
        website: dtoCommunity.website,
        phone: dtoCommunity.phone,
        title: dtoCommunity.title,
        description: dtoCommunity.description,
        features: dtoCommunity.features,
        bannerFile: dtoCommunity.banner_file,
        logoFile: dtoCommunity.logo_file,
        faviconFile: dtoCommunity.favicon_file,
        consultations: [],
        customPages: [],
        languageEnabled: dtoCommunity.language_enabled,
        contactInfo: dtoCommunity.contact_info,
        options: dtoCommunity.options,
        organizationName: dtoCommunity.organization_name ?? '',
        projects: dtoCommunity.projects,
        featuredProject: dtoCommunity.featured_project ? {
            itemType: constant.HomePageItem.Type.Project,
            id: dtoCommunity.featured_project.id,
            slug: dtoCommunity.featured_project.slug,
            visibility: dtoCommunity.featured_project.visibility,
            bannerFile: dtoCommunity.featured_project.banner_file,
            title: dtoCommunity.featured_project.title,
            beginDate: dtoCommunity.featured_project.begin_date ? new Date(dtoCommunity.featured_project.begin_date) : null,
            endDate: dtoCommunity.featured_project.end_date ? new Date(dtoCommunity.featured_project.end_date) : null,
            privateEmailAllowed: dtoCommunity.featured_project.private_email_allowed,
        } : null,
    }
}
