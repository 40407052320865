// @ts-ignore
import { Quasar, Notify } from "quasar";
import "quasar/dist/quasar.css";
import Material from "quasar/icon-set/material-icons";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import {
    QAvatar,
    QIcon,
    QForm,
    QInput,
    QBtn,
    QBtnToggle,
    QCardSection,
    QCardActions,
    QCard,
    QColor,
    QDialog,
    QCarousel,
    QTooltip,
    QCarouselSlide,
    QCircularProgress,
    QLinearProgress,
    QMenu,
    QSeparator,
    QBadge,
    QSelect,
    QItem,
    QItemSection,
    QRadio,
    QCheckbox,
    QItemLabel,
    QTab,
    QTabPanels,
    QTabPanel,
    QChip,
    QPagination,
    QBanner

} from "quasar";

export const options = {
    iconSet: Material,
    plugins: {
        Notify,
    },
    components: {
        QBtnToggle,
        QForm,
        QIcon,
        QInput,
        QBtn,
        QCardSection,
        QCardActions,
        QCard,
        QColor,
        QDialog,
        QCarousel,
        QCarouselSlide,
        QTooltip,
        QAvatar,
        QCircularProgress,
        QLinearProgress,
        QMenu,
        QSeparator,
        QBadge,
        QSelect,
        QItem,
        QItemSection,
        QCheckbox,
        QRadio,
        QItemLabel,
        QTab,
        QTabPanels,
        QTabPanel,
        QChip,
        QPagination,
        QBanner
    },
};

Notify.setDefaults({
    position: "top-right",
    timeout: 2500,
    group: false,
    progress: true,
    progressClass: "toaster-progress",
    actions: [
        {
            icon: "close",
            color: "black",
        },
    ],
});

export const quasar = Quasar;
