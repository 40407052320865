<script setup lang="ts">

import { ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import { useI18n } from 'vue-i18n';
import BaseButton from "@/components/base/BaseButton.vue";
import { authService } from "@/services/auth.service";
import { constant } from "@/constant";
import { HomepageItem } from "@/store/homepageItem.interface";


// PROPS
const props = defineProps<{
    homeLink: boolean;
    needLogin: boolean;
    item: HomepageItem;
}>();

// REFS
const { t } = useI18n();
const homepageItemPrivateNoAccessDialogRef = ref<InstanceType<typeof BaseDialog>>();


// FUNCTIONS
function openDialog() {
    homepageItemPrivateNoAccessDialogRef.value?.openDialog();
}

function closeDialog() {
    homepageItemPrivateNoAccessDialogRef.value?.closeDialog();
}


defineExpose({
    openDialog,
    closeDialog,
});

function goBackHomeClick() {
    window.location.href = "/"
}

function loginClick() {
    if(props.item.itemType === constant.HomePageItem.Type.Consultation) {
        authService.login();
    } else if (props.item.itemType === constant.HomePageItem.Type.Project){
        authService.login({ project: { slug: props.item.slug } });
    }
}

function getTranslationByItemType(key: string): string {
    const value = (() => {
        switch (props.item.itemType) {
            case constant.HomePageItem.Type.Project:
                return 'project';
            case constant.HomePageItem.Type.Consultation:
            default:
                return 'consultation';
        }
    })();
    return t(`dialog.homepageItemPrivateNoAccessDialog.${value}.${key}`);
}

</script>

<template>
    <BaseDialog ref="homepageItemPrivateNoAccessDialogRef">
        <template v-slot:title>
            <div class="homepageItemPrivateNoAccess-title">
                {{ getTranslationByItemType('title') }}
            </div>
        </template>
        <template v-slot:content>
            <div class="homepageItemPrivateNoAccess-content">
                <p v-if="needLogin">
                    {{ getTranslationByItemType('contentNeedLogin') }}
                </p>
                <p v-else>
                    {{ getTranslationByItemType('content') }}
                </p>
                <div class="homepageItemPrivateNoAccess-actions">
                    <BaseButton v-if="homeLink" class="action" @click="goBackHomeClick">{{
                            t('dialog.homepageItemPrivateNoAccessDialog.home')
                        }}
                    </BaseButton>
                    <BaseButton v-if="needLogin" class="action" @click="loginClick">{{
                            t('dialog.homepageItemPrivateNoAccessDialog.login')
                        }}
                    </BaseButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.homepageItemPrivateNoAccess {

    &-title {
        font-size: $medium-title;
    }

    &-actions {
        display: flex;
        justify-content: end;
        margin-top: $space-md;
    }

}

.base-dialog {
    background-color: $color-neutral-background !important;
}
</style>