<script setup lang="ts">
import {computed} from "vue";
import Banner from "@/components/layout/Banner.vue";
import { useI18n } from 'vue-i18n';
import { currentLocale } from "@/plugins/i18n";
import {Community} from "@/store/communityStore";

const { t } = useI18n();

// PROPS
const props = defineProps<{ community: Community }>();

// COMPUTED
const appLocale = computed(() => currentLocale())
const isSubjectToLaw96 = computed(() => {
    return props.community.options.loi96 ?? false;
})
</script>

<template>
  <Banner :message="t('banner.loi96')" v-if="appLocale === 'en' && isSubjectToLaw96"/>
</template>

<style scoped lang="scss">

</style>