import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { CancelTokenSource } from 'axios';
import { useCommunityStore } from '@/store/communityStore';
import {Consultation} from '@/store/consultation.interface';

const CancelToken = axios.CancelToken;
let cancelTokenConsultations: CancelTokenSource | null = null;

class ConsultationsService {
  async getConsultationBySlug(slug: string): Promise<Consultation> {
    if (cancelTokenConsultations) {
      cancelTokenConsultations.cancel('Multiple requests.');
    }
    cancelTokenConsultations = CancelToken.source();
    const response = await apiService.get(`${Config.api.backend.urlV2}/consultations/slug:${slug}`);
    const consultationData: any = response.data;
    if (consultationData) {
      const communityStore = useCommunityStore();
      communityStore.setConsultation(consultationData);
    }
    return response.data;
  }
}

export const consultationsService = new ConsultationsService();