import { constant } from "@/constant";
import { StoredFile } from "./common.interface";
import { HomepageItem } from "./homepageItem.interface";
import { LanguageTranslation } from "./language.interface";
import { Module } from "./module.interface";

export interface DTOProject {
    id: number;
    community_id: number;
    slug: string;
    status: number;
    visibility: number;
    banner_file: StoredFile;
    title: LanguageTranslation;
    description: LanguageTranslation;
    order_num: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    begin_date?: string;
    end_date?: string;
    private_email_allowed: PrivateAllowed | null;
    options: any;
    is_template: boolean;
}

export interface Project extends HomepageItem {
    id: number;
    communityId: number;
    visibility: number;
    bannerFile: StoredFile;
    orderNum: number;
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date;
    modules?: Module[];
    options: any;
    privateEmailAllowed: PrivateAllowed | null;
    isTemplate: boolean;
}

interface PrivateAllowed {
    email: [];
    domain: [];
}

export function parseProject(dto: DTOProject): Project {
    return {
        itemType: constant.HomePageItem.Type.Project,
        id: dto.id,
        communityId: dto.community_id,
        slug: dto.slug,
        status: dto.status,
        visibility: dto.visibility,
        bannerFile: dto.banner_file,
        title: dto.title,
        description: dto.description,
        orderNum: dto.order_num,
        createdAt: new Date(dto.created_at),
        updatedAt: new Date(dto.updated_at),
        deletedAt: dto.deleted_at ? new Date(dto.deleted_at) : undefined,
        beginDate: dto.begin_date ? new Date(dto.begin_date) : null,
        endDate: dto.end_date ? new Date(dto.end_date) : null,
        options: dto.options,
        privateEmailAllowed: dto.private_email_allowed,
        isTemplate: dto.is_template
    } as Project;
}