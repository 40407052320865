import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { CancelTokenSource } from 'axios';
import { DTOCommunity } from "@/store/communityStore";

const CancelToken = axios.CancelToken;
let cancelTokenPlatforms: CancelTokenSource | null = null;

class CommunitiesService {
  async getCommunity(): Promise<DTOCommunity> {

    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const response = await apiService.get(`${Config.api.backend.urlV2}/community`);
      return response.data;
    } catch (error) {
      window.location.href = 'http://www.cocoriko.org';
    }
  }
}

export const communitiesService = new CommunitiesService();
