import { Config } from '@/config';
import apiService from "@/services/api.service";
import { DTOProject } from '@/store/project.interface';
import { useCommunityStore } from '@/store/communityStore';

class ProjectsService {
    async getProjectBySlug(slug: string) {
        try {
            const response = await apiService.get(`${Config.api.backend.urlV2}/projects/slug:${slug}`);
            const data: DTOProject = response.data;
            if (data) {
                useCommunityStore().setProject(data);
            }
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
    async getProjectTemplateBySlug(projectSlug: string) {
        try {
            const response = await apiService.get(`${Config.api.backend.urlV2}/project-templates/${projectSlug}`);
            const data = response.data
            if (data) {
                useCommunityStore().addProjectTemplate(data);
            }
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
}

export const projectsService = new ProjectsService();