<script setup lang="ts">

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { currentLocale } from "@/plugins/i18n";
import { intlFormat } from "date-fns";

import SocialNetworks from "./SocialNetworks.vue";
import KeyInfo from "@/components/KeyInfo.vue";

import { constant } from "@/constant";
import { useCommunityStore } from "@/store/communityStore";


// PROPS
const props = defineProps<{
    startDate?: Date | null;
    endDate?: Date | null;
    title: string;
    image?: string;
    keyInformation?: object;
    isPrivate?: boolean;
    itemType: number;
    isFeatured?: boolean;
    slug?: string;
}>();


// CONST
const { t } = useI18n();
const communityStore = useCommunityStore();
const router = useRouter();


// FUNCTIONS
function formatDate(date: Date) {
    return intlFormat(date, { dateStyle: 'long'}, { locale: currentLocale() });
}

function getTranslationByItemType(key: string): string {
    const itemType = (() => {
        switch (props.itemType) {
            case constant.HomePageItem.Type.Project:
                return 'project';
            case constant.HomePageItem.Type.Consultation:
            default:
                return 'consultation';
        }
    })();
    return t(`dialog.homepageItemPrivateNoAccessDialog.${itemType}.${key}`);
}

function goToProject() {
    if(props.slug) {
        router.push({
            name: "project",
            params: { slug: props.slug }
        });
    }
}
</script>
<template>
    <div class="homepage-card" :class="{featured: props.isFeatured}">
        <img
            v-if="props.image"
            class="homepage-card__image"
            :src="props.image"
            alt="card-image"
        />
        <div class="homepage-card__content">
            <div class="top">
                <div class="homepage-card__title" :class="{featured: props.isFeatured}">
                    <h2>{{ props.title }}</h2>
                </div>
            </div>
            <div class="bottom">
                <div class="homepage-card__infos">
                    <div class="featured" v-if="props.isFeatured">
                        <div v-if="props.startDate && props.endDate" class="homepage-card__date" :class="{featured: props.isFeatured}">
                            {{formatDate(props.startDate) }} - {{formatDate(props.endDate) }}
                        </div>
                        <div v-else-if="props.endDate" class="homepage-card__date" :class="{featured: props.isFeatured}">
                            {{ t('consultation.end-date') }}: {{formatDate(props.endDate) }}
                        </div>
                        <div v-if="props.isFeatured" class="homepage-card__actions" :class="{featured: props.isFeatured}">
                            <q-btn fab color="secondary" @click="goToProject">
                                <span class="q-mx-sm">{{ t('buttons.go-to-project') }}</span>
                            </q-btn>
                        </div>
                    </div>
                    <div class="left" v-else>
                        <div v-if="props.startDate && props.endDate" class="homepage-card__date" :class="{featured: props.isFeatured}">
                            {{formatDate(props.startDate) }} - {{formatDate(props.endDate) }}
                        </div>
                        <div v-else-if="props.endDate" class="homepage-card__date" :class="{featured: props.isFeatured}">
                            {{ t('consultation.end-date') }}: {{formatDate(props.endDate) }}
                        </div>
                        <!-- <div v-if="props.isFeatured" class="homepage-card__actions">
                            <q-btn fab color="secondary" @click="goToProject">
                                <span class="q-mx-sm">{{ t('buttons.go-to-project') }}</span>
                            </q-btn>
                        </div> -->
                        <KeyInfo type="consultation" isSmall :key-information="props.keyInformation" />
                        <SocialNetworks :community="communityStore.community"/>
                    </div>
                    <div class="right" v-if="props.isPrivate">
                        <q-chip square icon="lock" :label="getTranslationByItemType('title')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.homepage-card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 200ms ease;
    height: 280px;
    background-color: $color-neutral-white;
    border-radius: 8px;

    &.featured {
        height: 100%;
    }

    &:hover {
        box-shadow: 0 6px 10px 0 rgba($color-neutral-grey-400-50, 0.2);
        // TODO: Improve outline transition, for now, let's only use a shadow
        transition: all 200ms ease;
    }

    &__infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;

        .left {
            display: flex;
            justify-content: end;
            flex-direction: column;
            height: 100%;
        }

        .right {
            display: flex;
            justify-content: end;
            flex-direction: column;
            height: 100%;
        }

        .featured {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $space-md;
        width: 100%;
        height: 100%;

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: end;
            .key-infos {
                justify-content: left;
            }
        }

        &-private {
            display: flex;
            gap: $space-sm;
            align-items: center;
            font-size: $sub-header;

            .q-icon {
                font-size: $small-title;
            }
        }
    }

    &__tags {
        display: flex;
        gap: $space-sm;
    }

    &__title {
        display: flex;
        align-items: center;
        line-height: 100%;
        margin-top: 0px;
        margin-bottom: 0px;

        &.featured {
            justify-content: center;
        }

        h2 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; 
            line-clamp: 2;
            overflow: hidden;
            max-height: calc(1.3em * 2); 
            text-overflow: ellipsis;
        }

    }

    &__date {
        display: flex;
        color: $color-neutral-grey-400-50;

        &.featured {
            display: flex;
            justify-content: center;
        }
    }

    &__image {
        width: 100%;
        max-height: 162px;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin-top: 14px;
    }
}
@media screen and (max-width: $breakpoint-sm) {
    .homepage-card__infos {
        flex-direction: column;
        justify-content: left;
        align-items: start;
        gap: $space-sm;

        .left {
            width: 100%;
            justify-content: start;
        }

        .right {
            justify-content: start;

            .q-chip {
                margin: 0;
            }
        }
    }
}
</style>
